import CookieStorage from "../polyfills/cookie";
import Storage from '../polyfills/storage'

const Device = (function() {

  let version = '1.02';

  'use strict';

  function browser() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return 'chrome'
      } else {
        return 'safari'
      }
    }

    return ''
  }

  function deviceType() {
    if (isIos()) { return 'ios_pwa' }

    return 'android_pwa'
  }

  function isSafari() {
    const ua = navigator.userAgent.toLowerCase();

    return (ua.indexOf('safari') != -1 && ua.indexOf('chrome') == -1)
  }

  function isChrome() {
    const ua = navigator.userAgent.toLowerCase();

    return (ua.indexOf('chrome') != -1 && !isSafari())
  }

  function isIos(){
    return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  }

  function isStandAlone() {
    return ('standalone' in window.navigator) && (window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
  }

  function init() {
    checkVersion();

    if (window.matchMedia('(display-mode: standalone)').matches) {
      if (isIos()){
        CookieStorage.setCookie('device_type', 'ios_pwa')
      } else {
        CookieStorage.setCookie('device_type', 'android_pwa')
      }
    } else {
      if (CookieStorage.getCookie('device_type') == 'android_pwa' || CookieStorage.getCookie('device_type') == 'ios_pwa' ) {
        CookieStorage.setCookie('device_type', 'browser')
      }
    }
  }

  function checkVersion() {
    const storedVersion = Storage.getItem('device_manager_version');
    if (storedVersion !== version) {
      CookieStorage.setCookie('device_type', '')
      Storage.setItem('device_manager_version', version);
    }
  }

  return {
    isIos: isIos,
    isStandAlone: isStandAlone,
    deviceType: deviceType,
    browser: browser,
    init: init,
    isChrome: isChrome,
    isSafari: isSafari
  }
})();

export default Device;
