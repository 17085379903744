import NotificationManager from "../pwa/notification_manager";
import device from "../pwa/device_manager";

window.addEventListener('load', function() {
  NotificationManager.init();
  if (!device.isIos()) {
    console.log('adding before install prompt')
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      console.log('registeringElement')
      NotificationManager.showRegisterElement(e);
    });
  }
});