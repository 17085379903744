const Switch = (function(){
  function init() {
    const switchElements = Array.from(document.querySelectorAll('[data-switch]'));
    switchElements.forEach(element => {
      handleSwitchElement(element)
    })
  }

  function handleSwitchElement(element){
    const input = element.querySelector('input')
    input.removeEventListener('change', onSwitch)
    input.addEventListener('change', onSwitch)
    handleSwitch(element)
  }

  function onSwitch(event) {
    const switchInputElement = event.currentTarget;
    handleSwitch(switchInputElement)
  }

  function handleSwitch(element){
    setSwitch(element, element.checked)
  }

  function setSwitch(element, value){
    let queryElement = element
    if (!queryElement.dataset.switch) {
      queryElement = queryElement.closest('[data-switch]')
    }

    const negativeLabel = queryElement.querySelector('.negative')
    const positiveLabel = queryElement.querySelector('.positive')

    positiveLabel.classList.remove('hidden')
    negativeLabel.classList.remove('hidden')

    if (element.type != 'radio' && element.type != 'checkbox') {
      element = element.querySelector('input')
    }

    element.checked = value

    if (element.checked) {
      negativeLabel.classList.add('hidden')
      positiveLabel.classList.remove('hidden')
    } else {
      negativeLabel.classList.remove('hidden')
      positiveLabel.classList.add('hidden')
    }
  }

  return {
    init: init,
    handleSwitch: handleSwitch,
    setSwitch: setSwitch
  }
})();

export default Switch;
