const CookieStorage = (function(){
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }
    else{
      return null;
    }
  }

  function setCookie(name, value) {
    document.cookie = name + "=" + value;
  }

  return {
    setCookie: setCookie,
    getCookie: getCookie
  }
})();

export default CookieStorage;
