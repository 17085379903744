const Helper = (function() {
  function isEmptyObject(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  function isNull(obj) {
    return obj == null || obj == undefined || obj == 'undefined'
  }

  function isEmpty(obj) {
    return isNull(obj) || obj == '' || isEmptyObject(obj);
  }

  function guard(entity, _function){
    if (window.isEmpty(entity)) { return; }
    _function();
  }

  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return {
    isEmptyObject: isEmptyObject,
    isNull: isNull,
    getParameterByName: getParameterByName,
    isEmpty: isEmpty,
    guard: guard
  }
})();

export default Helper
